<template>
  <cs-dialog
    class="cs-dialog-from"
    ref="dialog"
    :title="title"
    :width="width"
    :submit="submit"
  >
    <a-form-model
      ref="form"
      :model="model"
      :rules="rules"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 10 }"
    >
      <slot></slot>
    </a-form-model>
  </cs-dialog>
</template>
<script>
export default {
  props: {
    title: String,
    width: {
      type: String,
      default: "720px",
    },
    model: {
      type: Object,
    },
    rules: {
      type: Object,
    },
    action: Function,
  },
  data() {
    return {};
  },
  methods: {
    async submit() {
      await this.api.validate.submitForm(this.$refs["form"]);
      const { action, model } = this;
      let res = await action(this.api.clone(model));
      const { code, msg } = res;
      if (msg) {
        await this.api.toast({ code, msg });
      }
      if (code != 0) {
        throw new Error();
      }
      return res;
    },
  },
};
</script>
<style lang="less">
.cs-dialog-from {
  .ant-form-item {
    margin-bottom: 8px;
  }
}
</style>
